export const frameworkPermissions = (actions: Set<string>) => {
  const MANAGE_FRAMEWORK = actions.has('manage-framework')
  const EDIT_FRAMEWORK = actions.has('edit-framework')
  const VIEW_FRAMEWORK_WORKLIST = actions.has('view-framework-worklist')
  const ACCEPT_FRAMEWORK_WORKLIST = actions.has('accept-framework-worklist')
  const REJECT_FRAMEWORK_WORKLIST = actions.has('reject-framework-worklist')
  const VIEW_FRAMEWORK = actions.has('view-framework')
  const CREATE_SNMS_DRAFT = actions.has('create-snms-draft')

  return {
    ACCEPT_FRAMEWORK_WORKLIST,
    CREATE_SNMS_DRAFT,
    EDIT_FRAMEWORK,
    MANAGE_FRAMEWORK,
    REJECT_FRAMEWORK_WORKLIST,
    VIEW_FRAMEWORK,
    VIEW_FRAMEWORK_WORKLIST,
  }
}
