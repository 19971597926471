var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"e75c035806366914f67408b0bf15fe7c6951fb1b"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const ENV = process.env.APP_ENV

if (['production', 'staging'].includes(ENV)) {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      'https://22d2287bc89d4e8fb2956b3de43a4e39@o1211337.ingest.sentry.io/6347599',
    environment: ENV,
    tracesSampleRate: 1.0,
  })
}
